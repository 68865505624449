<template>
  <el-dialog
    :modal="false"
    :close-on-click-modal="false"
    title="关联品牌"
    :visible.sync="showDialog"
    width="1100px"
    top="5vh"
  >
    <div class="wrapper">
      <div class="content">
        <div class="left">
          <div class="top">
            <el-input v-model="lqueryForm.keyword" placeholder="输入品牌名称">
              <i
                slot="suffix"
                class="el-input__icon el-icon-search"
                @click="handleQuery"
              ></i>
            </el-input>
          </div>
          <el-table
            ref="leftTable"
            v-loading="loading1"
            stripe
            :data="lTableList"
            border
            height="420px"
            @selection-change="setSelectRows1"
          >
            <el-table-column
              align="center"
              type="selection"
              width="50"
            ></el-table-column>
            <el-table-column
              prop="brand_name"
              label="品牌名称"
              width="auto"
              align="center"
            ></el-table-column>
          </el-table>
          <el-pagination
            :current-page="lqueryForm.pageNo"
            :layout="llayout"
            :page-size="lqueryForm.pageSize"
            :total="ltotal"
            background
            @current-change="lhandleCurrentChange"
            @size-change="lhandleSizeChange"
          ></el-pagination>
          <!-- <div class="bottom">
            <el-button type="primary" @click="add">添加</el-button>
          </div> -->
        </div>
        <div class="middle">
          <!-- <div class="a">
            <el-button
              type="primary"
              size="mini"
              icon="el-icon-arrow-left"
            ></el-button>
          </div> -->
          <div class="b" style="margin-top: 10px">
            <el-button
              type="primary"
              size="mini"
              icon="el-icon-arrow-right"
              @click="add"
            ></el-button>
          </div>
        </div>
        <div class="right">
          <div class="top" style="line-height: 32px">
            供应商：{{ supplierText }}
          </div>
          <el-table
            ref="rightTable"
            v-loading="loading2"
            stripe
            :data="rTableList"
            border
            height="420px"
            @selection-change="setSelectRows2"
          >
            <el-table-column
              align="center"
              type="selection"
              width="50"
            ></el-table-column>
            <el-table-column
              prop="brand_name"
              label="已关联品牌"
              align="center"
              width="auto"
            ></el-table-column>
            <el-table-column align="center" label="操作" width="90">
              <template #default="{ $index, row }">
                <el-button type="text" @click="deleteRow($index, row)">
                  删除
                </el-button>
              </template>
            </el-table-column>
          </el-table>
          <div
            class="bottom"
            style="padding-left: 10px; margin-top: 20px; text-align: right"
          >
            <el-button @click="batchDeleteRow">批量删除</el-button>
          </div>
          <!-- <el-pagination
            :current-page="rqueryForm.pageNo"
            :layout="llayout"
            :page-size="rqueryForm.pageSize"
            :total="ltotal"
            background
            @current-change="rhandleCurrentChange"
            @size-change="rhandleSizeChange"
          ></el-pagination> -->
        </div>
      </div>
      <div
        slot="footer"
        class="dialog-footer"
        style="margin-top: 5px; text-align: right"
      >
        <el-button type="primary" @click="save">保存</el-button>
        <el-button @click="close">取 消</el-button>
      </div>
    </div>
  </el-dialog>
</template>
<script>
  import { getBrandList, getRtableList, linkBrandUpdate } from '@/api/brandList'
  export default {
    name: 'LinkBrand',
    components: {},
    data() {
      return {
        id: 0,
        loading1: true,
        loading2: false,
        showDialog: false,
        supplierText: '供应商',
        lTableList: [],
        rTableList: [],
        selectRow1: [],
        selectRow2: [],
        ltotal: 0,
        llayout: 'total, sizes, prev, next, jumper',
        lqueryForm: {
          pageNo: 1,
          pageSize: 10,
          keyword: '',
        },
        rqueryForm: {
          pageNo: 1,
          pageSize: 10,
        },
      }
    },
    computed: {
      subData() {
        return {
          suppid: this.id,
          brand_data: JSON.stringify(this.rTableList),
        }
      },
    },
    watch: {
      showDialog(val) {
        if (val) {
          this.rtableList()
          this.lfetchData()
        } else {
          this.lTableList = []
          this.rTableList = []
          this.loading2 = true
          this.lqueryForm.keyword = ''
          this.$refs.leftTable.clearSelection()
        }
      },
    },
    created() {
      this.lfetchData()
    },
    methods: {
      // 左表格数据
      async lfetchData() {
        let { data, code, msg, totalCount } = await getBrandList(
          this.lqueryForm
        )
        console.log(data)
        if (code == 200) {
          this.lTableList = data
          this.ltotal = totalCount
        } else {
          this.$message.error(msg)
        }
        this.loading1 = false
      },
      // 获取右边表格数据
      async rtableList() {
        let { data, msg, code } = await getRtableList({ id: this.id })
        console.log(data)
        if (code == 200) {
          this.rTableList = data
        } else {
          this.$message.error(msg)
        }
        this.loading2 = false
      },
      // 删除右边row
      deleteRow(index, row) {
        this.rTableList.splice(index, 1)
      },
      // 批量删除
      batchDeleteRow() {
        // console.log(this.selectRow2)
        // this.rTableList.forEach((item, index) => {
        //   if (this.selectRow2.filter((item2) => item2.id == item.id)[0]) {
        //     console.log(index)
        //     this.rTableList.splice(index, 1)
        //   }
        // })
        for (let i = 0; i < this.rTableList.length; i++) {
          for (let j = 0; j < this.selectRow2.length; j++) {
            if (this.rTableList[i].id == this.selectRow2[j].id) {
              this.rTableList.splice(i, 1)
            }
          }
        }
      },
      setSelectRows1(row) {
        console.log(row)
        this.selectRow1 = row
      },
      setSelectRows2(row, e) {
        console.log(row, e)
        this.selectRow2 = row
      },
      add() {
        this.selectRow1.forEach((item, index) => {
          if (
            this.rTableList.filter((item2) => item.id == item2.id).length !== 0
          ) {
            // this.$message.error('品牌 ' + item.brand_name + ' 已关联')
            this.$refs.leftTable.clearSelection()
          } else {
            this.rTableList.push(item)
            // console.log(this.lTableList[index])
            // this.$refs.leftTable.toggleRowSelection(this.lTableList[index])
          }
        })
        this.$refs.leftTable.clearSelection()
      },
      save() {
        console.log(this.subData)
        linkBrandUpdate(this.subData).then((res) => {
          if (res.code == 200) {
            this.$message.success('保存成功')
            this.$emit('refresh')
            this.showDialog = false
          } else {
            this.$message.error(res.msg)
          }
        })
      },
      close() {
        this.showDialog = false
      },
      // 查询品牌
      handleQuery() {
        this.lfetchData()
      },
      lhandleCurrentChange(val) {
        this.loading1 = true
        this.lqueryForm.pageNo = val
        this.lfetchData()
      },
      lhandleSizeChange(val) {
        this.loading1 = true
        this.lqueryForm.pageSize = val
        this.lfetchData()
      },
      // rhandleCurrentChange(val) {
      //   this.loading1 = true
      //   this.rqueryForm.pageNo = val
      //   this.rfetchData()
      // },
      // rhandleSizeChange(val) {
      //   this.loading1 = true

      //   this.rqueryForm.pageSize = val
      //   this.rfetchData()
      // },
    },
  }
</script>
<style lang="scss" scoped>
  .wrapper {
    min-height: 500px;
  }
  .content {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-around;
    text-align: center;
  }
  .top {
    margin-bottom: 10px;
  }
  .bottom {
    margin-top: 10px;
    text-align: right;
  }
  .left {
    width: 300px;
  }
  .middle {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 65px;
  }
  .right {
    width: 600px;
  }
</style>
