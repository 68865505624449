import request from '@/utils/request'
export function getIndexList(data) {
  return request({
    url: '/baseAdmin/supplier-user/index',
    method: 'post',
    data,
  })
}

// 获取详情
export function getDetail(data) {
  return request({
    url: '/baseAdmin/supplier-user/detail',
    method: 'post',
    data,
  })
}

// 修改

export function editData(data) {
  return request({
    url: '/baseAdmin/supplier-user/update',
    method: 'post',
    data,
  })
}

// 创建
export function createSupp(data) {
  return request({
    url: '/baseAdmin/supplier-user/create',
    method: 'post',
    data,
  })
}
// 删除

export function deleteSupp(data) {
  return request({
    url: '/baseAdmin/supplier-user/delete',
    method: 'post',
    data,
  })
}

// 改变状态

export function changeStatus(data) {
  return request({
    url: '/baseAdmin/supplier-user/status',
    method: 'post',
    data,
  })
}
