var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrapper" },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            modal: false,
            "close-on-click-modal": false,
            title: _vm.title,
            visible: _vm.showDialog,
          },
          on: {
            "update:visible": function ($event) {
              _vm.showDialog = $event
            },
          },
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              staticClass: "content",
            },
            [
              _c(
                "el-form",
                {
                  ref: "form",
                  staticStyle: { "text-align": "center" },
                  attrs: {
                    model: _vm.form,
                    "label-width": "100px",
                    rules: _vm.rules,
                    inline: "",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { prop: "supp_name", label: "供应商名称" },
                    },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.form.supp_name,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "supp_name", $$v)
                          },
                          expression: "form.supp_name",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { prop: "supp_address", label: "地址" },
                    },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.form.supp_address,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "supp_address", $$v)
                          },
                          expression: "form.supp_address",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { prop: "supp_contact", label: "联系人" },
                    },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.form.supp_contact,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "supp_contact", $$v)
                          },
                          expression: "form.supp_contact",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { prop: "supp_phone", label: "电话" },
                    },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.form.supp_phone,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "supp_phone", $$v)
                          },
                          expression: "form.supp_phone",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { prop: "is_close", label: "状态" },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "checked",
                          staticStyle: { width: "200px" },
                        },
                        [
                          _c(
                            "el-radio",
                            {
                              attrs: { label: 0 },
                              model: {
                                value: _vm.form.is_close,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "is_close", $$v)
                                },
                                expression: "form.is_close",
                              },
                            },
                            [_vm._v("正常")]
                          ),
                          _c(
                            "el-radio",
                            {
                              attrs: { label: 1 },
                              model: {
                                value: _vm.form.is_close,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "is_close", $$v)
                                },
                                expression: "form.is_close",
                              },
                            },
                            [_vm._v("封存")]
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "dialog-footer",
                  staticStyle: { "margin-top": "5px", "text-align": "right" },
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _vm.isEdit == 0
                    ? _c(
                        "el-button",
                        { attrs: { type: "primary" }, on: { click: _vm.save } },
                        [_vm._v(" 保存 ")]
                      )
                    : _vm.isEdit == 1
                    ? _c(
                        "el-button",
                        { attrs: { type: "primary" }, on: { click: _vm.add } },
                        [_vm._v(" 添加 ")]
                      )
                    : _vm._e(),
                  _c("el-button", { on: { click: _vm.close } }, [
                    _vm._v("取 消"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }