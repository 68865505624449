<template>
  <div class="orderTest-container">
    <el-button type="primary" @click="handleAdd">添加供应商</el-button>
    <el-table
      ref="tableSort"
      v-loading="loading"
      stripe
      :data="list"
      style="margin-top: 15px"
      @selection-change="setSelectRows"
      @cell-click="cellClick"
    >
      <!-- 序号 -->
      <el-table-column align="center" type="index" width="50">
        <template slot="header">
          <el-popover popper-class="custom-table-checkbox" trigger="hover">
            <el-checkbox-group v-model="checkList">
              <el-checkbox
                v-for="(item, index) in columns"
                :key="index"
                :label="item.label"
              ></el-checkbox>
            </el-checkbox-group>
            <el-button slot="reference" type="text">
              <vab-remix-icon icon="settings-line" />
            </el-button>
          </el-popover>
        </template>
      </el-table-column>
      <!-- 选择框 -->
      <el-table-column
        align="center"
        type="selection"
        width="90"
      ></el-table-column>
      <el-table-column
        v-for="(item, index) in finallyColumns"
        :key="index"
        align="center"
        :label="item.label"
        :prop="item.prop"
        width=""
      >
        <template v-if="item.prop == 'brand_count'" #default="{ row }">
          <span
            style="text-decoration: underline; cursor: pointer"
            @click="linkBrand(row)"
          >
            {{ row.brand_count }}
          </span>
        </template>
        <template v-else-if="item.prop == 'is_close'" #default="{ row }">
          {{ row.is_close == 0 ? '正常' : '封存' }}
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="操作"
        fixed="right"
        show-overflow-tooltip
        width="130"
      >
        <template #default="{ row }">
          <el-button type="text" @click="handleEdit(row)">编辑</el-button>
          <!-- <el-button
            v-if="row.is_close == 0"
            type="text"
            @click="handleSave(row)"
          >
            封存
          </el-button> -->
          <el-button
            v-if="row.is_close == 1"
            type="text"
            @click="handleDelete(row)"
          >
            删除
          </el-button>
          <!-- bill_status_text -->
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page="queryForm.pageNo"
      :layout="layout"
      :page-size="queryForm.pageSize"
      :total="total"
      background
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    ></el-pagination>
    <edit ref="edit" @refresh="fetchData"></edit>
    <link-brand ref="linkBrand" @refresh="fetchData"></link-brand>
  </div>
</template>

<script>
  import _ from 'lodash'
  import { getIndexList, deleteSupp, changeStatus } from '@/api/supplier'
  import Edit from './components/edit'
  import LinkBrand from './components/linkBrand'
  export default {
    name: 'Supplier2',
    components: {
      Edit,
      LinkBrand,
    },
    data() {
      return {
        loading: true,
        list: [],
        layout: 'total, sizes, prev, pager, next, jumper',
        checkList: ['名称', '地址', '联系人', '电话', '关联品牌', '状态'],
        columns: [
          {
            order: 1,
            width: '140',
            label: '名称',
            prop: 'supp_name',
          },
          {
            order: 2,
            width: '160',
            label: '地址',
            prop: 'supp_address',
          },
          {
            order: 3,
            width: '90',
            label: '联系人',
            prop: 'supp_contact',
          },
          {
            order: 4,
            width: '120',
            label: '电话',
            prop: 'supp_phone',
          },
          {
            order: 5,
            width: 'auto',
            label: '关联品牌',
            prop: 'brand_count',
          },
          {
            order: 6,
            width: '80',
            label: '状态',
            prop: 'is_close',
          },
        ],
        total: 0,
        queryForm: {
          pageNo: 1,
          pageSize: 10,
        },
      }
    },
    computed: {
      finallyColumns() {
        let list = []
        this.checkList.forEach((item) => {
          list.push(this.columns.filter((col) => col.label === item)[0])
        })
        // console.log(list)

        return _.sortBy(list, (item) => {
          item.order
        })
      },
    },
    created() {
      this.fetchData()
    },
    methods: {
      setSelectRows() {},
      cellClick() {},
      fetchData() {
        getIndexList(this.queryForm).then((res) => {
          if (res.code == 200) {
            console.log(res.data)
            this.list = res.data
            this.total = res.totalCount
            this.loading = false
          } else {
            this.$message.error(res.msg)
          }
        })
      },
      // 表格关联品牌点击
      linkBrand(row) {
        console.log(row)
        this.$refs['linkBrand'].id = row.id
        this.$refs['linkBrand'].supplierText = row.supp_name

        this.$refs['linkBrand'].showDialog = true
      },
      handleAdd() {
        this.$refs['edit'].isEdit = 1
        this.$refs['edit'].showDialog = true
      },
      handleEdit(row) {
        console.log(row)
        this.$refs['edit'].isEdit = 0
        this.$refs['edit'].id = row.id
        this.$refs['edit'].showDialog = true
      },
      handleSave(row) {
        console.log(row)
        changeStatus({ id: row.id }).then((res) => {
          console.log(res)
          if (res.code == 200) {
            this.$message.success(res.msg)
            this.fetchData()
          } else {
            this.$message.error(res.msg)
          }
        })
      },
      handleDelete(row) {
        console.log(row)
        // deleteSupp(row.id)
        this.$confirm('确定删除此项吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            deleteSupp({
              id: row.id,
            }).then((res) => {
              console.log(res)
              if (res.code == 200) {
                this.$message.success('删除成功')
                this.fetchData()
              } else {
                this.$message.error(res.msg)
              }
            })
          })
          .catch(() => {
            console.log('no')
          })
      },
      handleCurrentChange(val) {
        this.queryForm.pageNo = val
        this.fetchData()
      },
      handleSizeChange(val) {
        this.queryForm.pageSize = val
        this.fetchData()
      },
    },
  }
</script>
