import request from '@/utils/request'

// brand 表格数据
export function getBrandList(data) {
  return request({
    url: '/baseAdmin/common/brand-list',
    method: 'post',
    data,
  })
}

// 已选择的品牌列表
export function getRtableList(data) {
  return request({
    url: '/baseAdmin/supplier-user/brand-list',
    method: 'post',
    data,
  })
}

// 更新供应商品牌
export function linkBrandUpdate(data) {
  return request({
    url: '/baseAdmin/supplier-user/update-brand',
    method: 'post',
    data,
  })
}
