var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        modal: false,
        "close-on-click-modal": false,
        title: "关联品牌",
        visible: _vm.showDialog,
        width: "1100px",
        top: "5vh",
      },
      on: {
        "update:visible": function ($event) {
          _vm.showDialog = $event
        },
      },
    },
    [
      _c("div", { staticClass: "wrapper" }, [
        _c("div", { staticClass: "content" }, [
          _c(
            "div",
            { staticClass: "left" },
            [
              _c(
                "div",
                { staticClass: "top" },
                [
                  _c(
                    "el-input",
                    {
                      attrs: { placeholder: "输入品牌名称" },
                      model: {
                        value: _vm.lqueryForm.keyword,
                        callback: function ($$v) {
                          _vm.$set(_vm.lqueryForm, "keyword", $$v)
                        },
                        expression: "lqueryForm.keyword",
                      },
                    },
                    [
                      _c("i", {
                        staticClass: "el-input__icon el-icon-search",
                        attrs: { slot: "suffix" },
                        on: { click: _vm.handleQuery },
                        slot: "suffix",
                      }),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading1,
                      expression: "loading1",
                    },
                  ],
                  ref: "leftTable",
                  attrs: {
                    stripe: "",
                    data: _vm.lTableList,
                    border: "",
                    height: "420px",
                  },
                  on: { "selection-change": _vm.setSelectRows1 },
                },
                [
                  _c("el-table-column", {
                    attrs: { align: "center", type: "selection", width: "50" },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "brand_name",
                      label: "品牌名称",
                      width: "auto",
                      align: "center",
                    },
                  }),
                ],
                1
              ),
              _c("el-pagination", {
                attrs: {
                  "current-page": _vm.lqueryForm.pageNo,
                  layout: _vm.llayout,
                  "page-size": _vm.lqueryForm.pageSize,
                  total: _vm.ltotal,
                  background: "",
                },
                on: {
                  "current-change": _vm.lhandleCurrentChange,
                  "size-change": _vm.lhandleSizeChange,
                },
              }),
            ],
            1
          ),
          _c("div", { staticClass: "middle" }, [
            _c(
              "div",
              { staticClass: "b", staticStyle: { "margin-top": "10px" } },
              [
                _c("el-button", {
                  attrs: {
                    type: "primary",
                    size: "mini",
                    icon: "el-icon-arrow-right",
                  },
                  on: { click: _vm.add },
                }),
              ],
              1
            ),
          ]),
          _c(
            "div",
            { staticClass: "right" },
            [
              _c(
                "div",
                { staticClass: "top", staticStyle: { "line-height": "32px" } },
                [_vm._v(" 供应商：" + _vm._s(_vm.supplierText) + " ")]
              ),
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading2,
                      expression: "loading2",
                    },
                  ],
                  ref: "rightTable",
                  attrs: {
                    stripe: "",
                    data: _vm.rTableList,
                    border: "",
                    height: "420px",
                  },
                  on: { "selection-change": _vm.setSelectRows2 },
                },
                [
                  _c("el-table-column", {
                    attrs: { align: "center", type: "selection", width: "50" },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "brand_name",
                      label: "已关联品牌",
                      align: "center",
                      width: "auto",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: { align: "center", label: "操作", width: "90" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var $index = ref.$index
                          var row = ref.row
                          return [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.deleteRow($index, row)
                                  },
                                },
                              },
                              [_vm._v(" 删除 ")]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "bottom",
                  staticStyle: {
                    "padding-left": "10px",
                    "margin-top": "20px",
                    "text-align": "right",
                  },
                },
                [
                  _c("el-button", { on: { click: _vm.batchDeleteRow } }, [
                    _vm._v("批量删除"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ]),
        _c(
          "div",
          {
            staticClass: "dialog-footer",
            staticStyle: { "margin-top": "5px", "text-align": "right" },
            attrs: { slot: "footer" },
            slot: "footer",
          },
          [
            _c(
              "el-button",
              { attrs: { type: "primary" }, on: { click: _vm.save } },
              [_vm._v("保存")]
            ),
            _c("el-button", { on: { click: _vm.close } }, [_vm._v("取 消")]),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }