<template>
  <div class="wrapper">
    <el-dialog
      :modal="false"
      :close-on-click-modal="false"
      :title="title"
      :visible.sync="showDialog"
    >
      <div v-loading="loading" class="content">
        <el-form
          ref="form"
          :model="form"
          label-width="100px"
          :rules="rules"
          inline
          style="text-align: center"
        >
          <el-form-item prop="supp_name" label="供应商名称" style="width: 100%">
            <el-input v-model="form.supp_name"></el-input>
          </el-form-item>
          <el-form-item prop="supp_address" label="地址" style="width: 100%">
            <el-input v-model="form.supp_address"></el-input>
          </el-form-item>
          <el-form-item prop="supp_contact" label="联系人" style="width: 100%">
            <el-input v-model="form.supp_contact"></el-input>
          </el-form-item>
          <el-form-item prop="supp_phone" label="电话" style="width: 100%">
            <el-input v-model="form.supp_phone"></el-input>
          </el-form-item>
          <el-form-item prop="is_close" label="状态" style="width: 100%">
            <div class="checked" style="width: 200px">
              <el-radio v-model="form.is_close" :label="0">正常</el-radio>
              <el-radio v-model="form.is_close" :label="1">封存</el-radio>
            </div>
          </el-form-item>
        </el-form>
        <div
          slot="footer"
          class="dialog-footer"
          style="margin-top: 5px; text-align: right"
        >
          <el-button v-if="isEdit == 0" type="primary" @click="save">
            保存
          </el-button>
          <el-button v-else-if="isEdit == 1" type="primary" @click="add">
            添加
          </el-button>
          <el-button @click="close">取 消</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
  import { getDetail, editData, createSupp } from '@/api/supplier'
  export default {
    name: 'Edit',
    components: {},
    data() {
      var checkPhone = (rule, value, callback) => {
        if (!value) {
          return callback()
        } else {
          const reg = /^1[3|4|5|7|8][0-9]\d{8}$/
          if (reg.test(value)) {
            callback()
          } else {
            return callback(new Error('请输入正确的手机号'))
          }
        }
      }
      return {
        id: 0,
        isEdit: 0,
        loading: true,
        showDialog: false,
        form: {
          id: 0,
          is_close: 0,
          supp_address: '',
          supp_contact: '',
          supp_name: '',
          supp_phone: '',
        },
        rules: {
          // supp_phone: [
          //   {
          //     required: false,
          //     validator: checkPhone,
          //     trigger: ['blur', 'change'],
          //   },
          // ],
        },
      }
    },
    computed: {
      title() {
        if (this.isEdit == 0) {
          return '编辑'
        } else {
          return '添加供应商'
        }
      },
    },
    watch: {
      showDialog(val) {
        if (val) {
          console.log('watch')
          console.log(val)
          // 是编辑
          if (this.isEdit == 0) {
            console.log('getD')
            getDetail({ id: this.id }).then((res) => {
              if (res.code == 200) {
                this.form = res.data
                this.loading = false
              } else {
                this.$message.error(res.msg)
              }
            })
          } else {
            this.loading = false
            // 添加供应商代码
            // 啊实打实的
          }
        } else {
          console.log('关闭')
          this.loading = true
          this.resetForm()
          this.$emit('refresh')
        }
      },
    },
    methods: {
      add() {
        console.log(this.form)
        this.form.id = null
        this.$refs['form'].validate((v) => {
          if (v) {
            createSupp(this.form).then((res) => {
              console.log(res)
              if (res.code == 200) {
                this.showDialog = false
                this.$message.success('创建成功')
              } else {
                this.$message.error(res.msg)
              }
            })
          } else {
            return false
          }
        })
      },
      save() {
        console.log(this.form)
        this.$refs['form'].validate((v) => {
          if (v) {
            editData(this.form).then((res) => {
              console.log(res)
              if (res.code == 200) {
                this.showDialog = false
                this.$message.success('保存成功')
              } else {
                this.$message.error(res.msg)
              }
            })
          } else {
            return false
          }
        })
      },
      close() {
        this.showDialog = false
      },
      resetForm() {
        this.$refs['form'].resetFields()
      },
    },
  }
</script>
<style lang="scss" scoped></style>
